import { BNBBSC, tBNB, WETH } from "./addresses";
import {
  BSwapfactoryContractAddress,
  BSwaprouter2ContractAddress,
} from "app-constants/dynamic";
import {
  AppOtherConfigByNetwork,
  AppTokenUrlConfig,
  Blockchain,
  EnvType,
  Networks,
  NetworkUrls,
  SupportedNetworks,
  TimeOption,
  TransactionSpeed,
  TransactionSpeedConfig,
} from "types";
import bnbImage from "Assets/images/icon-3.png";
import ethImage from "Assets/images/b-icon.png";
import BSCIcon from "Assets/images/bnb-icon.png";
import ETHIcon from "Assets/images/eth-icon.png";
import PolygonIcon from "Assets/images/polygon-logo.svg";
import { getEnvType } from "./env";

export * from "./addresses";
export * from "./env";
export * from "./dynamic";
export * from "./bond";
export * from "./localStorage";
export * from "./tokens";

export const INFRA_PROJECT_ID = "9ad30e10e54f4b6992caa8e785ec6bd2";

export const SUPPORTED_CHAIN_IDS = [1, 56, 97]; // 137 is added to avoid not connecting wallet
export const NETWORK_URLS: NetworkUrls = {
  1: `https://mainnet.infura.io/v3/${INFRA_PROJECT_ID}`,
  56: "https://bsc-dataseed.binance.org/",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

export const NETWORK_ICONS: AppOtherConfigByNetwork = {
  1: ethImage,
  56: bnbImage,
  97: bnbImage,
};

export const BlockChains: Blockchain[] = [
  {
    chainId:
      getEnvType() === EnvType.development
        ? SupportedNetworks.bsctestnetwork
        : SupportedNetworks.bsc,
    icon: BSCIcon,
    name: "Binance Smart Chain",
  },
  {
    chainId: SupportedNetworks.mainnet,
    icon: ETHIcon,
    name: "Ethereum",
  },
];

export const DEFAULT_CHAIN_ID = SupportedNetworks.mainnet; // ethereum
// DMC pool address in BSC
export const DMC_POOL_ADDRESS_BSC =
  "0x17d389be3778c18757e02b79c39bad54fd02643a";

// Degen token address in bnb
export const DEGEN_TOKEN_ADDRESS_BSC =
  "0x1Eea643fc6C0B4D253621839986fc566Fce40704";

export const DMC_TOKEN_ADDRESS = {
  1: "0x3d28ece0f13c44a665b374b38bb40f501ceb6404",
  56: "0x9b707e2a6a917b1b26de80bf17b33cc88e6cb1db",
  97: "0x3d28ece0f13c44a665b374b38bb40f501ceb6404",
};

export const URL_ARRAY: AppTokenUrlConfig = {
  1: [
    {
      tokenListUrl: "https://www.gemini.com/uniswap/manifest.json",
      enabled: true,
      locked: true,
    },
    {
      tokenListUrl: "https://tokens.coingecko.com/uniswap/all.json",
      enabled: true,
      locked: true,
    },
    {
      tokenListUrl: "https://api.1inch.exchange/v3.0/1/tokens",
      alterName: "1inch",
      alterImage:
        "https://tokens.1inch.io/0x111111111117dc0aa78b770fa6a738034120c302.png",
      enabled: false,
      locked: true,
    },
    {
      tokenListUrl:
        "https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json",
      enabled: false,
      locked: true,
    },
  ],
  56: [
    {
      tokenListUrl: "https://tokens.pancakeswap.finance/coingecko.json",
      enabled: true,
      locked: true,
    },
    {
      tokenListUrl:
        "https://tokens.pancakeswap.finance/pancakeswap-extended.json",
      enabled: true,
      locked: true,
    },
    {
      tokenListUrl: "https://tokens.pancakeswap.finance/cmc.json",
      enabled: true,
      locked: true,
    },
  ],
  97: [
    {
      tokenListUrl: "/test-apis/tbsc-tokens.json",
      enabled: true,
      locked: true,
    },
  ],
};

export const CHAIN_NAME_BY_ID: AppOtherConfigByNetwork = {
  1: "eth",
  56: "bsc",
  97: "bsc_test",
};

// [network id]: Object
export const NETWORKS: Networks = {
  1: {
    HTTP_PROVIDER_URL: NETWORK_URLS[1],
    W_TOKEN_ADDRESS: WETH,
    CHAIN: 1,
    NAME: "Ethereum",
    FACTORY: BSwapfactoryContractAddress[SupportedNetworks.mainnet], // bswap factory address
    ROUTER: BSwaprouter2ContractAddress[SupportedNetworks.mainnet], // bswap router address
    ExplorerUrl: "https://etherscan.io",
  },
  56: {
    HTTP_PROVIDER_URL: NETWORK_URLS[56],
    W_TOKEN_ADDRESS: BNBBSC,
    CHAIN: 56,
    NAME: "Binance",
    FACTORY: BSwapfactoryContractAddress[SupportedNetworks.bsc], // bswap factory address
    ROUTER: BSwaprouter2ContractAddress[SupportedNetworks.bsc], // bswap router address
    ExplorerUrl: "https://bscscan.com",
  },
  97: {
    HTTP_PROVIDER_URL: NETWORK_URLS[97],
    W_TOKEN_ADDRESS: tBNB,
    CHAIN: 97,
    NAME: "Test Binance",
    FACTORY: BSwapfactoryContractAddress[SupportedNetworks.bsctestnetwork], // bswap factory address
    ROUTER: BSwaprouter2ContractAddress[SupportedNetworks.bsctestnetwork], // bswap router address
    ExplorerUrl: "https://testnet.bscscan.com",
  },
};

export const DEX_TOOL_CHAINNAMES: NetworkUrls = {
  1: "ether",
  56: "bsc",
  97: "tbsc",
};

export const DEFAULT_SLIPPAGE = 0.1;
export const TRANSACTION_SPEED: TransactionSpeedConfig = {
  STANDARD: TransactionSpeed.STANDARD,
  FAST: TransactionSpeed.FAST,
  INSTANT: TransactionSpeed.INSTANT,
};

export const TimeOptions: Array<TimeOption> = [
  {
    label: "Months",
    value: 30 * 24 * 60 * 60, // 30 day * 24 hr * 60 mins * 60 seconds
  },
  {
    label: "Days",
    value: 24 * 60 * 60, // 24 hr * 60 mins * 60 seconds
  },
  {
    label: "Hours",
    value: 60 * 60, // 60 mins * 60 seconds
  },
  {
    label: "Minutes",
    value: 60, //  60 seconds
  },
  {
    label: "Seconds",
    value: 1, // 1 sec
  },
];
